import React from "react";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        maxWidth: 1120,
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between",
        },
    },
    container: {
        display: "flex",
        justifyContent: "center",
        marginTop: "12px",
    },
    toggleButton: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
    },
    button: {
        background: theme.colorCard?.primary || theme.palette?.primary?.main,
        margin: 16,
        [theme.breakpoints.down("sm")]: {
            margin: 8,
        },
    },
    rect: {
        marginTop: "8px",
        maxWidth: "384px",
        width: "100%",
    },
    teaser: {
        marginTop: 40,
        marginBottom: 40,
    },
    teaserContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "16px",
        maxWidth: 1120,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    verticalTeaser: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 40,
    },
    item: {
        margin: 16,
        [theme.breakpoints.down("sm")]: {
            margin: 8,
        },
    },
    headerContainer: {
        display: "flex",
        justifyContent: "center",
        height: 73,
    },
    logo: {
        display: "flex",
        width: "100%",
        maxWidth: 1120,
        justifyContent: "flex-start",
        alignItems: "center",
    },
    picture: {
        margin: 8,
        marginRight: 16,
        marginLeft: 16,
    },
    menuItem: {
        margin: 8,
        marginRight: 16,
        marginLeft: 16,
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    hero: {
        marginTop: 8,
    },
    mobMenu: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        margin: 12,
    },
}));
export const SkeletonLayout = React.memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (React.createElement(Box, null,
        React.createElement(Box, { className: classes.headerContainer },
            React.createElement(Box, { className: classes.logo },
                React.createElement(Skeleton, { variant: 'rect', height: '60px', width: '250px', className: classes.picture }))),
        React.createElement(Box, { className: classes.headerContainer },
            React.createElement(Box, { className: classes.header },
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '100px', className: classes.menuItem }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '100px', className: classes.menuItem }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '100px', className: classes.menuItem }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '100px', className: classes.menuItem }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '100px', className: classes.menuItem }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '80px', className: classes.mobMenu }),
                React.createElement(Skeleton, { variant: 'rect', height: '40px', width: '80px', className: classes.mobMenu }))),
        React.createElement(Box, { className: classes.hero },
            React.createElement(Skeleton, { variant: 'rect', height: '375px' })),
        React.createElement(Box, { className: classes.container },
            React.createElement(Skeleton, { variant: 'text', height: '60px', width: '400px' })),
        React.createElement(Box, { className: classes.container },
            React.createElement(Skeleton, { variant: 'rect', height: '60px', width: '300px', className: classes.item }),
            " ",
            React.createElement(Skeleton, { variant: 'rect', height: '60px', width: '300px', className: classes.item }),
            " ",
            React.createElement(Skeleton, { variant: 'rect', height: '60px', width: '300px', className: classes.item })),
        React.createElement(Box, { className: classes.container },
            React.createElement(Skeleton, { variant: 'rect', height: '280px', width: '1120px', className: classes.teaser })),
        React.createElement(Box, { className: classes.container },
            React.createElement(Skeleton, { variant: 'rect', height: '280px', width: '1120px', className: classes.teaser })),
        React.createElement(Box, { className: classes.verticalTeaser },
            React.createElement(Box, { className: classes.teaserContainer },
                React.createElement(Skeleton, { variant: 'rect', height: '500px', width: '300px', className: classes.item }),
                React.createElement(Skeleton, { variant: 'rect', height: '500px', width: '300px', className: classes.item }),
                React.createElement(Skeleton, { variant: 'rect', height: '500px', width: '300px', className: classes.item }))),
        React.createElement(Box, null,
            React.createElement(Skeleton, { variant: 'rect', height: '146px' }))));
});
export default SkeletonLayout;
